// @flow

/**
 * Module dependencies.
 */

import { type Node, useEffect } from 'react';
import { navigate } from 'gatsby';

/**
 * `NotFound` page.
 */

const NotFound = (): Node => {
  useEffect(() => {
    navigate('/');
  }, []);

  return null;
};

/**
 * Export `NotFound` page.
 */

export default NotFound;
